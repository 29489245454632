
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';

const codeInit = [] as any;

export default defineComponent({
  name: 't2o-code-field',
  components: {
    Field,
  },
  emits: ['inputCode'],
  props: {
    digits: {
      type: Number,
      default: 6,
    },
    colClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    codeInput: codeInit,
  }),
  computed: {
    codeStyle() {
      return `font-size: 1.5em; text-align:center; height: 30pt;`;
    },
    containerStyle() {
      const fieldWidth = Math.ceil(100 / this.digits);
      return `width: calc(50vh * .${fieldWidth}); `;
    },
  },
  methods: {
    handleKeypress(e, index) {
      if (typeof e.target.value !== 'undefined' && e.target.value !== '') {
        const currentInputDOM = document.querySelector(
          `input[name='code[${index}]']`
        ) as unknown as HTMLInputElement;

        const digitValue = currentInputDOM.value.substr(0, -1);
        this.codeInput[index] = digitValue;
        currentInputDOM.nodeValue = this.codeInput[index];

        console.log('handleKeypress', {
          currentInputDOM: currentInputDOM,
          currentInputDOMValue: currentInputDOM.value,
          currentInputDOMValueLength: currentInputDOM.value.length,
          digitValue: digitValue,
          currentInputDOMV: currentInputDOM.nodeValue,
        });
      }
    },

    handleKeyup(e, index) {
      if (typeof e.target.value !== 'undefined' && e.target.value !== '') {
        const currentInputDOM = document.querySelector(
          `input[name='code[${index}]']`
        ) as unknown as HTMLInputElement;
        const nextInputDOM = document.querySelector(
          `input[name='code[${index + 1}]']`
        ) as unknown as HTMLInputElement;

        if (
          typeof currentInputDOM !== 'undefined' &&
          currentInputDOM.value.length > 0
        ) {
          const digitValue = currentInputDOM.value.substr(-1);
          this.codeInput[index] = digitValue;
          currentInputDOM.nodeValue = this.codeInput[index];
        }

        if (typeof nextInputDOM !== 'undefined' && nextInputDOM !== null) {
          nextInputDOM.focus();
        }

        this.$emit('inputCode', index, this.codeInput[index]);
      }
    },
  },
  watch: {
    code(value) {
      console.log('code', value);
    },
    formSubmitted(value) {
      this.codeInput = value ? [] : codeInit;
    },
  },
});
