
import { defineComponent } from 'vue';
import FormField from '@/components/forms/FormField.vue';
import States from '@/components/forms/StateSelect.vue';
import Countries from '@/components/forms/CountriesSelect.vue';
import { Actions, Getters } from '@/store/enums/ResourcesEnums';
import { mapActions, mapGetters, mapState } from 'vuex';

export default defineComponent({
  name: 'address-form',
  emits: ['countryChange', 'countryBlur', 'stateChange', 'stateBlur'],
  props: {
    containerClass: {
      type: String,
      default: 'd-flex flex-column',
    },
    values: {
      type: Object,
      default: () => undefined,
    },
    country: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    requiredFields: {
      default: () => {
        return ['address_line', 'post_code', 'city', 'state', 'country'];
      },
    },
  },
  components: {
    FormField,
    Countries,
    States,
  },
  data: () => ({
    options: [] as unknown as [],
    loading: false,
    isLoad: false,
  }),
  computed: {
    ...mapGetters({
      getStates: Getters.GET_LIST_STATES,
    }),
  },
  methods: {
    ...mapActions({
      listStates: Actions.FETCH_STATE,
    }),
    addPrefix(name, prefix = '') {
      return prefix ? `${prefix}_${name}` : name;
    },
    handleCountryClose() {
      this.$emit('countryBlur');
    },
    handleCountrySelect(value) {
      this.handleCountryStatesSelect(value);
      this.$emit('countryChange', value);
    },
    async handleCountryStatesSelect(value) {
      if (value) {
        await this.listStates(value).then(async () => {
          this.options = await this.getStates;
        });
      }
    },
    handleStateSelect(value) {
      this.$emit('stateChange', value);
    },
    handleStateClose() {
      this.$emit('stateBlur');
    },
    isRequired(field) {
      return this.requiredFields.includes(field);
    },
  },
});
