import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_States = _resolveComponent("States")!
  const _component_Countries = _resolveComponent("Countries")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex flex-column mb-10", _ctx.containerClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormField, {
        required: _ctx.isRequired('address_line'),
        name: _ctx.addPrefix('address_line', _ctx.prefix),
        containerClass: "col-lg-6",
        label: "Address",
        placeholder: "Address",
        type: "text"
      }, null, 8, ["required", "name"]),
      _createVNode(_component_FormField, {
        required: _ctx.isRequired('city'),
        name: _ctx.addPrefix('city', _ctx.prefix),
        containerClass: "col-lg-6",
        label: "Suburb",
        placeholder: "Suburb",
        type: "text"
      }, null, 8, ["required", "name"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_States, {
        required: _ctx.isRequired('state'),
        options: _ctx.options,
        value: _ctx.state,
        name: _ctx.addPrefix('state', _ctx.prefix),
        containerClass: "col-lg-4",
        onChange: _ctx.handleStateSelect,
        onClose: _ctx.handleStateClose
      }, null, 8, ["required", "options", "value", "name", "onChange", "onClose"]),
      _createVNode(_component_FormField, {
        required: _ctx.isRequired('post_code'),
        name: _ctx.addPrefix('post_code', _ctx.prefix),
        mask: '####',
        containerClass: "col-lg-4",
        label: "Post Code",
        placeholder: "Post Code",
        type: "text"
      }, null, 8, ["required", "name"]),
      _createVNode(_component_Countries, {
        required: _ctx.isRequired('country'),
        value: _ctx.country,
        name: _ctx.addPrefix('country', _ctx.prefix),
        label: "Country",
        containerClass: "col-lg-4",
        onChange: _ctx.handleCountrySelect,
        onClose: _ctx.handleCountryClose
      }, null, 8, ["required", "value", "name", "onChange", "onClose"])
    ])
  ], 2))
}