
import { defineComponent } from 'vue';
import ProfileForm from '@/views/v2/views/pages/Profile/OverviewForm.vue';
import LinkMobileModal from '@/components/modals/forms/LinkMobileModal.vue';
import {
  Getters as AuthGetters,
  Actions as AuthActions,
} from '@/store/enums/AuthEnums';
import { mapGetters, mapActions } from 'vuex';
import parser from '@/utils/parser';
import time from '@/utils/time';
import { Actions as SystemActions } from '@/store/enums/ResourcesEnums';
import mask from '@/utils/mask';
import toasts from '@/utils/toasts';
import text from '@/utils/text';

export default defineComponent({
  name: 'profile-overview',
  data: () => ({
    isEdit: false,
    loading: false,
    sendCodeMessage: '',
    showVerifyMobileModal: false,
  }),
  components: {
    ProfileForm,
    LinkMobileModal,
  },
  mounted() {
    this.listAllCountries();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      actionErrors: AuthGetters.GET_AUTH_USER_ACTION_ERROR,
      isMobileVerified: AuthGetters.GET_MOBILE_IS_VERIFIED,
    }),
    countryName() {
      return parser.country(this.authUser.country);
    },
    stateName() {
      return parser.state(this.authUser.state);
    },
    mobileNumber() {
      return text.formatMobile(this.authUser.mobile_number)
    },
  },
  methods: {
    ...mapActions({
      listAllCountries: SystemActions.FETCH_COUNTRIES,
      sendcode: AuthActions.SEND_MOBILE_CODE,
    }),
    loadVerifyMobileModal() {
      const params = {
        mobile_number: this.authUser.mobile_number,
      };

      this.sendcode(params)
        .then(() => {
          this.sendCodeMessage =
            'Verification code successfully sent to your mobile number';
          this.showVerifyMobileModal = true;
        })
        .catch(() => {
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        });
    },
    handleMobileVerified() {
      this.showVerifyMobileModal = false;
    },
    handleEdit(value) {
      this.isEdit = value;
    },

    handleFormSubmmitted() {
      this.isEdit = false;
    },
    formatDate(date) {
      return time.dateFormat(date, 'aus');
    },
  },
});
