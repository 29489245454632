
import { defineComponent } from 'vue';
import Modal from '@/views/v2/views/components/Modal.vue';

import CodeField from '@/components/forms/CodeField.vue';
import { Form } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';

import {
  Getters as AuthGetters,
  Actions as AuthActions,
} from '@/store/enums/AuthEnums';
import toasts from '@/utils/toasts';

export default defineComponent({
  name: 'link-mobile-modal',
  emits: ['mobileVerified', 'update:modelValue'],
  props: {
    modelValue: { type: Boolean, default: false },
    sendCodeMessage: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    modal: {} as HTMLElement,
    formData: {} as any,
    form: {} as unknown as typeof Form,
    accountCodes: [] as any,
    formSubmitted: false,
    loading: false,
  }),
  mounted() {
    this.form = this.$refs.verificationForm as typeof Form;
  },
  components: {
    Modal,
    Form,
    CodeField,
  },
  computed: {
    ...mapGetters({
      actionErrors: AuthGetters.GET_AUTH_USER_ACTION_ERROR,
    }),
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    formSchema() {
      return {};
    },
  },
  methods: {
    ...mapActions({
      verify: AuthActions.VERIFIY_MOBILE,
    }),

    async submitForm() {
      if (this.loading) return;
      this.loading = true;

      const params = {
        code: this.accountCodes.join(''),
      };

      this.form.resetForm();
      this.accountCodes = [];

      return await this.verify(params)
        .then(() => {
          toasts.success('Mobile number successfully verified.', () => {
            this.loading = false;

            setTimeout(() => {
              this.$emit('update:modelValue', false);
            }, 2000);
          });
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        });
    },
    handleCodeInput(index, value) {
      this.accountCodes[index] = value;
    },
    resetForm() {
      this.form.resetForm();

      // Reset the form values
      this.accountCodes = [];
    },
  },
});
