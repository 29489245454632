
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import FormField from '@/components/forms/FormField.vue';
import { Form } from 'vee-validate';
import { ProfileSchema } from '@/schemas/profile.schema';
import { Actions, Getters } from '@/store/enums/ResourcesEnums';
import AddressForm from '@/components/forms/AddressForm.vue';
import maskUtil from '@/utils/mask';
import {
  Getters as AuthGetters,
  Actions as AuthActions,
} from '@/store/enums/AuthEnums';
import toasts from '@/utils/toasts';
import { Auth } from '@/models/AuthModel';
import time from '@/utils/time';

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  date_of_birth: '',
  state: '',
  country: 'AU',
} as unknown as Auth;

export default defineComponent({
  name: 'profile-update',
  props: {
    profile: {
      type: Object,
      default: () => {
        return initialValues;
      },
    },
  },
  emits: ['formSubmmitted', 'editMode'],
  data: () => ({
    // eslint-disable-next-line
    options: [] as any,
    loading: false,
    formData: initialValues,
    form: {} as unknown as typeof Form,
  }),
  mounted() {
    this.formData = this.profile as unknown as Auth;
    this.form = this.$refs.profileForm as typeof Form;
  },
  components: {
    FormField,
    Form,
    AddressForm,
  },
  computed: {
    ...mapGetters({
      actionError: AuthGetters.GET_AUTH_USER_ACTION_ERROR,
      getStates: Getters.GET_LIST_STATES,
    }),
    formSchema() {
      return ProfileSchema;
    },
    maskingMobile() {
      return maskUtil.mobile.aus;
    },
    initValues() {
      return this.profile
        ? {
            ...this.profile,
            ...{
              date_of_birth: this.formatDate(this.profile.date_of_birth),
              country: this.profile.country ?? 'AU',
            },
          }
        : initialValues;
    },
  },
  methods: {
    ...mapActions({
      updateProfile: AuthActions.UPDATE_USER_PROFILE,
      listStates: Actions.FETCH_STATE,
    }),
    disabledDates(time: Date) {
      return time.getTime() > Date.now();
    },
    handleCountryChange(value) {
      this.listStates(value);
      this.setFieldValue('country', value);
    },
    handleCountryBlur() {
      this.validateFormField('country');
    },
    handleStateChange(value) {
      this.setFieldValue('state', value);
    },
    handleStateBlur() {
      this.validateFormField('state');
    },
    submitForm(values) {
      if (this.loading) return;
      this.loading = true;

      this.formData = {
        ...this.formData,
        ...values,
        ...{
          date_of_birth: this.formatDate(this.profile.date_of_birth, 'default'),
        },
      };

      // remove unrelated pre-filled formData
      delete this.formData.create_new_password;
      // end:: remove unrelated pre-filled formData

      this.updateProfile(this.formData)
        .then(() => {
          toasts.success('Profile Successfully Updated');
          this.$emit('formSubmmitted');
        })
        .catch(() => {
          if (this.actionError) {
            const { errors, message } = this.actionError;
            toasts.error(errors, message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    cancel() {
      this.$emit('editMode', false);
    },
    formatDate(date, format = 'aus') {
      return time.dateFormat(date, format);
    },
  },
});
