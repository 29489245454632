
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';

const __default__ = defineComponent({
  props: {
    modelValue: { type: Boolean, default: false },
    width: { type: String, default: '900px' },
  },
  data() {
    return {
      modal: null,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        (this.modal as any)?.show();
      } else {
        (this.modal as any)?.hide();
      }
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    this.modal = new Modal(this.$refs.modalDialog, {
      backdrop: 'static',
      keyboard: false,
    });
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2e79bf04": (_ctx.width)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__