import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex flex-row flex-nowrap justify-content-center", _ctx.colClass])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parseInt(_ctx.digits), (index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        style: _normalizeStyle(_ctx.containerStyle),
        class: ""
      }, [
        _createVNode(_component_Field, {
          name: `code[${index}]`,
          modelValue: _ctx.codeInput[index],
          "onUpdate:modelValue": ($event: any) => ((_ctx.codeInput[index]) = $event),
          autocomplete: "false",
          disabled: _ctx.disabled,
          placeholder: "",
          type: "number",
          maxlength: 1,
          min: 0,
          max: 9,
          onKeypress: ($event: any) => (_ctx.handleKeypress($event, index)),
          onKeyup: ($event: any) => (_ctx.handleKeyup($event, index)),
          style: _normalizeStyle(_ctx.codeStyle),
          class: "form-control w-50 p-2 mx-auto border-dark"
        }, null, 8, ["name", "modelValue", "onUpdate:modelValue", "disabled", "onKeypress", "onKeyup", "style"])
      ], 4))
    }), 128))
  ], 2))
}